import React from "react";
import resumeFile from "../documents/cv.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Who am I?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Aaron,</span> and I build software
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              I've been designing and engineering software systems for over 15 years.
              I have designed scalable, distributed microservice systems; built data pipelines using Apache Kafka;
              deployed serverless applications to AWS and integrated service-oriented architectures using IBM Integration Bus.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              I've written countless sytems in C#, JavaScript and Python. I've built web apps with React and APIs using ASP.NET.
              I have used PostgreSQL, SQL Server, Oracle, MySQL and DynamoDB.
              I'm an enthusiast of Event Sourcing, Apache Kafka, Kubernetes and DevOps.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Over the years working as a developer and architect in the banking and fintech sectors, I have accrued a wealth of
              skills, experience and industry knowledge. But my favourite thing about this job is that there's always something
              new to learn, novel technologies to discover and innovative ideas that inspire and delight.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Aaron Proctor
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:enquiries@aaronproctor.uk">enquiries@aaronproctor.uk</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>{new Date(new Date() - new Date('1982-02-01')).getFullYear() - 1970}
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Coventry,
                  UK
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>15</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>1000</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  git commits
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>28k</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Cups of tea drank
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
